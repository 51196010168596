/* ------------------------------------------------------------
	ToC
------------------------------------------------------------ */

/* purgecss start ignore */

.toc-wrap {
	margin-bottom: 20px;
	.next-page {
		display: none;
	}
	&.affix {
		@media (max-width: 767px) {
			margin-bottom: 20px;
		}
	}
}

.toc-vert {
	width: 100%;
	padding: 0px 0px 0 0px;
	z-index: 99999;
	text-transform: uppercase;
	line-height: 20px;

	a {
		color: #fff;
		text-decoration: none !important;
		font-size: 18px;
		display: block;
		text-align: center;
		max-width: 100px;
		transition: color 0.05s ease-in-out;
		padding: 11px 15px 13px 16px;
		height: 40px;
	}

	.toc-inner {
		padding: 0;
		margin: 0 auto !important;
		background-color: #0e1012;
		height: 65px;
		overflow: hidden;

		@media (max-width: 767px) {
			margin-left: -492px !important;
			left: 50%;
			position: relative;
			height: 65px;
			overflow: hidden;
			width: 984px;
		}
	}

	.toc-inception {
		float: left;
		position: relative;
		left: 50%;
	}

	ol {
		position: relative;
		left: -50%;
		padding: 0;
		float: left;
		margin: 0 !important;
		list-style-type: none;
		height: 65px;
	}

	li {
		font-size: 13px;
		font-weight: normal;
		float: left;
		border: 1px solid #333;
		border-width: 0 0 0 1px;
		height: 65px;
		&:first-child {
			border: 0px;
			a {
				padding-left: 18px;
			}
		}
	}

	.lowprice {
		display: none;
	}

	a:hover {
		text-decoration: none;
		color: red;
		transition: color 0.05s ease-in-out;
	}

	li.active a {
		color: red;
	}

	.share {
		width: 150px;
		float: none;
		display: none;
		margin-left: 20px;
		margin-bottom: 10px;
		* {
			margin: 0 5px 0 0;
		}
	}
	&.affix {
		background-color: #0e1012;
		position: fixed;
		top: 0px;
		left: 0%;
		margin: 0px !important;
		width: 100%;
		transition: all 0.05s ease-in-out-out;

		@media (max-width: 767px) {
			position: static;
			top: 0px;
			left: 0%;
			margin: 0 auto !important;
			width: auto;
			background-color: #0e1012;
			transition: all 0.05s ease-in-out-out;
			.toc-inner {
				margin-left: auto !important;
				left: auto;
				position: static;
				height: auto;
				overflow: hidden;
				width: 100%;
			}

		}

	}


}

.toc-wrap-top.affix {
	margin-bottom: 102px;
}

@media (max-width: 767px) {

	.toc {
		width: auto;
		max-width: 400px;
		margin: 0 auto;
		padding: 0px 0px 0 0px;
		line-height: 20px;
		overflow: hidden;
		a {
			font-size: 18px;
			text-align: left;
			max-width: none;
			padding: 10px;
			height: auto;
			text-transform: capitalize;
			border: 0px solid #0e1012;
			display: block;
			br {
				display: none;
			}
		}

		.toc-inner {
			height: auto;
			overflow: visible;
			padding: 10px 0 15px 0;
		}
		.toc-inception {
			float: none;
			left: 0;
		}
		ol {
			left: 0%;
			float: none;
			list-style-type: none;
			height: auto;
		}
		li {
			font-size: inherit;
			font-weight: normal;
			float: none;
			border: 0px;
			height: auto;
			max-width: 100%;
			&:first-child {
				border: 0px;
			}
			&:first-child a {
				padding-left: 12px;
			}
		}
		.lowprice {
			display: none;
		}

		ol {
			position: relative;
			max-width: 350px;
			margin: 0 auto !important;
		}
		li {
			padding-left: 0px;
			width: 49%;
			display: inline-block;
			a {
				padding: 10px 4px 10px 12px;
			}
		}
	}

	.review-toc__more {
		position: static;
	}

	.review-toc-more-dd {
		width: 100%;
	}

	.toc li.videos {
		display: none;
	}

}

.toc-bot {
	background-color: #0e1012;
	width: 575px;
	text-transform: uppercase;
	line-height: 24px;
	padding: 30px 20px 10px 20px;
	a {
		color: #fff;
		text-decoration: none !important;
		font-size: 20px;
		display: block;
		width: auto;
		transition: color 0.05s ease-in-out;
	}
	ol {
		padding: 0;
		float: left;
		width: 170px;
	}

	li {
		color: #999;
		font-size: 18px;
		font-weight: normal;
		margin: 0 0 10px 0 !important;
	}

	a:hover {
		text-decoration: none;
		color: red;
		transition: color 0.05s ease-in-out;
	}

	li.active a {
		color: red;
	}

	@media (max-width: 767px) {
		display: none;
		.toc-inception {
			position: relative;
		}
		ol {
			width: auto;
			margin: 0 !important;
			li {
				margin: 0 0 2px 0 !important;
			}
			&[start="4"],
			&[start="7"] {
				position: static;
			}
		}

	}
}

@media (max-width: 400px) {

	.toc {
		width: auto;
		max-width: 400px;
		margin: 0 -12px;
	}

	.toc-vert.affix {
		margin: 0 -12px !important;
		width: auto;
	}

	.review-toc--horiz .review-toc__inner ol {
		width: 60%;
	}

}


/* LENS REVIEW TOC */

.toc-lens_reviews {
	.toc-vert a {
		font-size: 17px;
		padding: 11px 10px 13px 10px;
	}

	li:first-child a {
		padding-left: 0px;
	}

	.toc-bot {
		li {
			width: auto;
			list-style-type: none;
			a {
				padding-left: 0;
			}

			br {
				display: none;
			}
		}

		.lens-fl-nav-pad {
			padding: 0;
			margin: 0;
		}
	}

	@media (max-width: 767px) {
		.toc .toc-inner {
			min-height: 210px;
		}

	}

}


/*focal length nav link vertical spacing*/

.lens-fl-nav-pad {
	margin-top: 9px;
	display: block;
	@media (max-width: 767px) {
		margin: 0;
	}
}


/*Responsive when viewport > element width*/

@media screen and (min-width: 1006px) {
	.review-toc--priority {
		margin: 0 calc(-49vw + 50%);
		padding: 0 10px;
		box-sizing: border-box;
	}
}

.review-toc-wrap {
	margin: 0 -12px 25px;
	body.sticky & {
		height: 65px;
	}
	.review-toc__more:hover .review-toc-more-dd { display: none; }
	&.ls_sticky {
		position: fixed;
		top: 0;
		width: 100%;
		left: 12px;
		right: 12px;
		z-index: 10;
	}
	a.lscrntmenu { color: red; }
}

.review-toc {
	transition: all 0.3s ease-in-out;
	position: relative;
	span.disabled,
	a {
		color: #fff;
		font-family: 'Arial Narrow', HelveticaNeue-CondensedBold, arial, sans-serif;
		font-family: AvenirNextCondensed-Bold,Futura-CondensedExtraBold,HelveticaNeue-CondensedBold,"Ubuntu Condensed","Liberation Sans Narrow","Franklin Gothic Demi Cond","Arial Narrow",sans-serif-condensed,Arial,"Lucida Grande",sans-serif;
		font-size: 15px;
		font-weight: bold;
		padding: 10px 13px;
		text-decoration: none !important;
		text-align: center;
		text-transform: uppercase;
		height: 45px;
		display: inline-block;
		display: flex;
		align-items: center;
		justify-content: center;
		line-height: 1.1;
		transition: color 0.05s ease-in-out;
		white-space: nowrap;
		@media (max-width: 400px) {
			height: 30px;
		}
	}
	a:hover {
		color: red;
	}

	li.disabled span.disabled,
	li.disabled a {
		color: #9A9E9E;
	}

	&--horiz {
		ol,
		ul {
			list-style-type: none;
			margin: 0 auto !important;
		}
		ol {
			display: flex;
			justify-content: center;
			>li:hover ul {
				display: block;
			}
		}
	}

	&__inner {
		position: relative;
		background-color: #0e1012;
		margin: 0 auto;
		width: 100%;
		.review-toc--priority & {
			max-width: 1360px;
		}
	}

	&-more-dd {
		background-color: #0e1012;
		display: none;
		position: absolute;
		top: 100%;
		right: 0;
		width: 10em;
		z-index: 2;
		padding: 6px 0 12px;
		a {
			display: block;
			height: auto;
			padding: 12px 13px;
		}
	}

	/*visible, horizontal list*/

	&__visible {
		padding-right: 110px;
	}

	/*drop down list*/

	&__overflow {
		position: absolute;
		right: 0px;
		top: 100%;
		width: 160px;
		background-color: #0e1012;
		z-index: 99;
	}

	/* Toggles drop down list*/

	&__more {
		position: relative;
	}

	&__jump {
		padding-right: 20px;
		position: relative;
		&:after {
			position: absolute;
			top: 23px;
			right: 0;
			content: "";
			width: 10px;
			height: 10px;
			border: 1px solid red;
			border-width: 0px 2px 2px 0;
			transform: rotate(45deg);
			@media (max-width: 400px) {
				top: 17px;
			}
		}
	}


	&__more--items-disabled {
		opacity: .5;
	}

	&__overflow {
		a {
			height: auto;
			padding: 12px 10px;
			display: block;
		}

		li.comments a {
			margin: 0 auto;
		}
		li {
			border: 1px solid #333;
			border-width: 0 0 1px 0;
		}

		a br {
			display: none;
		}
	}

	/*desktop only*/

	&--horiz .review-toc__visible li:not(:first-child) {
		border: 1px solid #333;
		border-width: 0 0 0 1px;
	}

	&__overflow--hidden,
	&__more--hidden {
		display: none;
	}

	.toc-review--hidden {
		opacity: 0;
		visibility: hidden;
	}

	&--cols {
		margin: 0 auto;
		ol {
			flex-wrap: wrap;
			padding: 0;
			margin: 0 !important;
			padding: 20px 5px 20px 5px;
			counter-reset: olcounter;
		}

		li span.disabled,
		li a {
			padding-left: 35px !important;
			padding-right: 5px !important;
			font-weight: bold !important;
			font-size: 20px;
			height: auto;
		}

		li {
			width: 33%;
			max-width: 33%;
			text-align: left;
			display: block;
			border: 1px solid #333;
			border-width: 0 0 0px 0;
			counter-increment: olcounter;
			position: relative;
		}

		li:before {
			content: counter(olcounter) ".";
			position: absolute;
			top: 4px;
			left: 5px;
			text-align: right;
			width: 25px;
			color: #fff;
			font-size: 18px;
			opacity: .7;
		}

		li.disabled:before {
			opacity: .25;
		}

		li br {
			display: none;
		}

		li span.disabled,
		li a {
			text-align: left;
			justify-content: flex-start;
			padding: 5px 0 5px 10px;
		}

		@media (max-width: 767px) {
			li {
				width: 50%;
				max-width: initial;
				height: auto !important;
				&:before {
					display: none;
				}

				span.disabled,
				a {
					padding: 12px;
					padding-left: 10px !important;
					text-transform: none;
					font-weight: normal;
					height: auto;
				}
			}

		}
	}

	&--cols,
	&--cols .review-toc__inner {
		min-width: none;
		min-width: auto;
	}


	&--bot {
		.review-toc__inner ol {
			columns: 2;
			@media screen and (min-width: 1006px) {
				columns: 3;
			}
		}
		li {
			a, span.disabled {
				font-size: 18px;
			}
		}
	}

}


body.sticky .review-toc--horiz {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 999999999;
}

a.review-toc__more--hidden+.review-toc__visible {
	padding-right: 0;
}

/* purgecss end ignore */